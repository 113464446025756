import React, { useEffect, useState, useContext } from "react";
import { css, jsx } from "@emotion/core";
import IncidentList from "../components/data/incident-list";
import styled from "@emotion/styled";
import YemeniMap from "../components/data/map";
import useTranslations from "components/hooks/useTranslations";
import { sort, toLower, descend, ascend, prop, equals } from "ramda";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { rhythm } from "utils/typography";
import { mq } from "utils/helper";
import { LocaleContext } from "context/locale-context";
import { colors } from "style/theme";
import Layout from "../components/layout";
import { graphql } from "gatsby";

const SortOption = styled.span({
  paddingRight: "0.6rem",
  paddingLeft: "0.6rem",
  cursor: "pointer",
  [mq[1]]: {
    padding: 0,
  },
});

function FilterBar({ isLoading, incidents, setIncidents, data }) {
  const tr = useTranslations();
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  const [currentFilter, setCurrentFilter] = useState("default");

  return (
    <div
      css={{
        marginBottom: rhythm(1.5),
        width: "70%",
        [mq[1]]: {
          margin: rhythm(1),
        },
      }}
    >
      {isLoading ? (
        <div>{tr("Loading")} ...</div>
      ) : (
        <div
          css={{
            display: "flex",
            [mq[1]]: {
              display: "block",
            },
          }}
        >
          <input
            placeholder={tr("Type to Search")}
            onChange={(event) => {
              if (event.target.value === "") {
                setIncidents(data);
              } else {
                const filteredData = data.filter((node) => {
                  if (node.title_en == null) {
                    return;
                  } else {
                    return toLower(node.title_en).includes(
                      toLower(event.target.value)
                    );
                  }
                });
                setIncidents(filteredData);
              }
            }}
            css={{
              border: "none",
              borderBottom: "2px solid black",
              background: "none",
              [mq[1]]: {
                marginTop: "1rem",
              },
            }}
          />
          <div
            css={{
              marginLeft: isRTL ? 0 : rhythm(2),
              marginRight: isRTL ? rhythm(2) : 0,
              display: "flex",
              alignItems: "flex-end",
              [mq[1]]: {
                margin: 0,
                display: "block",
              },
            }}
          >
            <span
              css={{
                fontWeight: "bold",
                paddingRight: isRTL ? 0 : "1rem",
                paddingLeft: isRTL ? "1rem" : 0,
                [mq[1]]: {
                  display: "block",
                  marginTop: "1rem",
                },
              }}
            >
              {tr("Sort")}:
            </span>
            <span>{tr("Date")}:</span>
            <SortOption>
              <span
                css={{
                  borderBottom:
                    equals(currentFilter, "dateDown") && "1px solid black",
                }}
              >
                <TiArrowSortedDown
                  onClick={() => {
                    const ordered = sort(
                      descend(({ date }) => new Date(date)),
                      incidents
                    );
                    setCurrentFilter("dateDown");
                    setIncidents(ordered);
                  }}
                />
              </span>

              <span
                css={{
                  borderBottom:
                    equals(currentFilter, "dateUp") && "1px solid black",
                }}
              >
                <TiArrowSortedUp
                  onClick={() => {
                    const ordered = sort(
                      ascend(({ date }) => new Date(date)),
                      incidents
                    );
                    setCurrentFilter("dateUp");
                    setIncidents(ordered);
                  }}
                />
              </span>
            </SortOption>
          </div>
        </div>
      )}
    </div>
  );
}

function Data({ data }) {
  const [hoveredUnit, setHoveredUnit] = useState("");
  const nodes = data.allIncidentsJson.edges.map((p) => p.node);
  const [incidents, setIncidents] = useState(nodes);
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";

  return (
    <Layout>
      <div
        css={{
          backgroundColor: colors.bg,
          paddingTop: rhythm(2.5),
          direction: isRTL ? "rtl" : "ltr",
        }}
      >
        <div
          css={{
            maxWidth: "1200px",
            margin: "auto",
          }}
        >
          <FilterBar
            incidents={incidents}
            setIncidents={setIncidents}
            isLoading={false}
            data={nodes}
          />

          <div
            css={{
              display: "grid",
              gridTemplateColumns: "repeat(2, minmax(20px, 1fr))",
              gridColumnGap: rhythm(1),
              maxWidth: "1200px",
              margin: "auto",
              [mq[0]]: {
                display: "block",
              },
            }}
          >
            <div>
              {incidents.map((incident) => (
                <IncidentList
                  incident={incident}
                  key={incident.code}
                  setHoveredUnit={setHoveredUnit}
                />
              ))}
            </div>
            <div
              css={{
                position: "sticky",
                top: "12vh",
                height: "100vh",
                boxSizing: "border-box",
              }}
            >
              <YemeniMap
                units={incidents}
                hoveredUnitID={hoveredUnit}
                viewport={{ lat: 15.5, lng: 45, zoom: 7 }}
                page="data"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Data;

export const pageQuery = graphql`
  query Data {
    allIncidentsJson {
      edges {
        node {
          id
          title_ar
          title_en
          date
          location
          code
          name_ar
          name_en
          latitude
          longitude
        }
      }
    }
  }
`;
