import React, { useContext, useState } from "react";
import { jsx, css } from "@emotion/core";
import PropTypes from "prop-types";
import { rhythm } from "utils/typography";
import LocalizedLink from "components/localizedLink";
import { LocaleContext } from "context/locale-context";
import { FaCalendarMinus } from "react-icons/fa";
import { TiLocation } from "react-icons/ti";
import { colors } from "style/theme";
import { formatDate } from "utils/helper";
import useTranslations from "../hooks/useTranslations";

function IncidentList({ incident, setHoveredUnit }) {
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  const tr = useTranslations();
  const { code, title_ar, title_en, date, name_ar, name_en } = incident;

  return (
    <LocalizedLink to={`data/incident/${code}`}>
      <div
        css={{
          backgroundColor: colors.light,
          marginBottom: rhythm(1),
          padding: `${rhythm(1)} ${rhythm(1.2)}`,
          ": hover": {
            boxShadow: "0 2px 3px 0 rgba(0,0,0,0.25)",
            cursor: "pointer"
          }
        }}
        onMouseEnter={() => setHoveredUnit(code)}
        onMouseLeave={() => setHoveredUnit("")}
      >
        <div
          css={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <small css={{ fontSize: "80%" }}>
            {tr("Incident")}: {code}
          </small>
        </div>
        <h3
          css={{
            marginTop: rhythm(0.4)
          }}
        >
          {isRTL ? title_ar : title_en}
        </h3>
        <div
          css={{
            display: "flex"
          }}
        >
          <div
            css={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "80%"
            }}
          >
            <FaCalendarMinus css={{ marginRight: "0.5rem" }} />
            {formatDate(date, locale)}
          </div>
          <div
            css={{ fontSize: "80%", marginLeft: rhythm(1), display: "flex" }}
          >
            <TiLocation css={{ marginRight: "0.3rem" }} />
            {isRTL ? name_ar : name_en}
          </div>
        </div>
      </div>
    </LocalizedLink>
  );
}

IncidentList.propTypes = {
  incident: PropTypes.object,
  setHoveredUnit: PropTypes.func
};

export default IncidentList;
